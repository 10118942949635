 const REST_API = 'https://shopamerica-api.skyniche.website/'

// const REST_API = 'http://127.0.0.1:5000/'

const URL="http://localhost:3000/"


function formatMoney(amount) {
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    let decimalPart = parts.length > 1 ? parts[1] : '';
  
    // If decimalPart is empty or has only one digit, add a trailing zero
    if (decimalPart.length === 0) {
      decimalPart = '00';
    } else if (decimalPart.length === 1) {
      decimalPart = decimalPart + '0';
    } else {
      decimalPart = decimalPart.slice(0, 2); // Take only the first two digits
    }
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + '.' + decimalPart;
  }

module.exports={REST_API,URL,formatMoney}